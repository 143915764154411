<template>
  <div class="view">
    <div style="margin-bottom: 60px; padding: 20px 0">
      <div class="container">
        <div class="wrapper">
          <div v-if="requestingCurrentRecord">Loading...</div>
          <div v-else>
            <h3>事件預覽</h3>
            <div class="card p-4 mb-3">
              <h5 class="mb-4">事件背景資訊</h5>
              <div class="row">
                <div class="col">
                  <div>
                    <h6>時間</h6>
                    <div class="mb-3">
                      {{ format(parseISO(currentRecord.occuredTime), 'yyyy/MM/dd HH:mm:ss') }}
                    </div>
                  </div>
                  <div>
                    <h6>地址</h6>
                    <div class="mb-3">
                      {{ currentRecord.zipCode }} {{ currentRecord.city }}{{ currentRecord.area
                      }}{{ currentRecord.address }}
                    </div>
                  </div>
                  <div v-show="currentRecord.locationDescription">
                    <h6>地點額外敘述</h6>
                    <div class="mb-3">{{ currentRecord.locationDescription }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card p-3 mb-3">
              <h5 class="mb-4">雙方身份</h5>
              <div class="row">
                <div class="col-lg-6">
                  <h6>我方身份</h6>
                  <div>
                    {{
                      $store.state.identity_options.find(
                        (v) => v.value === currentRecord.accidentParties[0].identity
                      ).text
                    }}
                  </div>
                  <div class="mb-3">
                    {{ currentRecord.accidentParties[0].plateNumber || '' }}
                  </div>
                  <!-- <div v-if="$store.state.a_vehicle_plate_photo">
                    <img :src="$store.state.a_vehicle_plate_photo.dataUrl" />
                  </div> -->
                </div>
                <div class="col-lg-6">
                  <h6>對方身份</h6>
                  <div>
                    {{
                      $store.state.identity_options.find(
                        (v) => v.value === currentRecord.accidentParties[1].identity
                      ).text
                    }}
                  </div>
                  <div class="mb-3">
                    {{ currentRecord.accidentParties[1].plateNumber || '' }}
                  </div>
                  <!-- <div v-if="$store.state.b_vehicle_plate_photo">
                    <img :src="$store.state.b_vehicle_plate_photo.dataUrl" />
                  </div> -->
                </div>
              </div>
            </div>

            <!-- <div
              class="card p-3 mb-3"
              v-show="
                $store.state.a_vehicle_damage_photo ||
                $store.state.b_vehicle_damage_photo ||
                $store.state.a_b_vehicle_damage_photo_1 ||
                $store.state.a_b_vehicle_damage_photo_2
              "
            >
              <h5>現場照片</h5>
              <div class="row">
                <div class="col-lg-6" v-if="$store.state.a_vehicle_damage_photo">
                  <h6>我方車輛受損的地方</h6>
                  <img :src="$store.state.a_vehicle_damage_photo.dataUrl" />
                </div>
                <div class="col-lg-6" v-if="$store.state.b_vehicle_damage_photo">
                  <h6>對方車輛受損的地方</h6>
                  <img :src="$store.state.b_vehicle_damage_photo.dataUrl" />
                </div>
                <div class="col-lg-6" v-if="$store.state.a_b_vehicle_damage_photo_1">
                  <h6>請將兩台車拍攝在同一張照片</h6>
                  <img :src="$store.state.a_b_vehicle_damage_photo_1.dataUrl" />
                </div>
                <div class="col-lg-6" v-if="$store.state.a_b_vehicle_damage_photo_2">
                  <h6>請換個角度拍攝兩台車的相對位置</h6>
                  <img :src="$store.state.a_b_vehicle_damage_photo_2.dataUrl" />
                </div>
              </div>
            </div> -->

            <div class="card p-3 mb-3">
              <h5>雙方受傷狀況</h5>
              <div class="row">
                <div class="col-lg-6">
                  <h6>我方受傷狀況</h6>
                  <div v-show="currentRecord.accidentParties[0].injured === true">有受傷</div>
                  <div v-show="currentRecord.accidentParties[0].injured === false">無受傷</div>
                  <!-- <div v-if="$store.state.a_inhury_photo">
                    <img :src="$store.state.a_inhury_photo.dataUrl" />
                  </div> -->
                </div>
                <div class="col-lg-6 mt-4">
                  <h6>對方受傷狀況</h6>
                  <div v-show="currentRecord.accidentParties[1].injured === true">有受傷</div>
                  <div v-show="currentRecord.accidentParties[1].injured === false">無受傷</div>
                  <!-- <div v-if="$store.state.b_inhury_photo">
                    <img :src="$store.state.b_inhury_photo.dataUrl" />
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottomButtonWrapper">
      <div class="backButton" @click="$router.back()">返回</div>
      <div class="creataContractButton" @click="$router.push('/contract/create')">製作和解書</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { parseISO, format } from 'date-fns';
import { record } from '@/mixins';

export default {
  mixins: [record],
  computed: {
    ...mapState({
      requestingCurrentRecord: (state) => state.records.requestingCurrentRecord,
      currentRecord: (state) => state.records.currentRecord,
    }),
  },
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {
    this.fetchRecordById({ id: this.$route.params.id });
  },
  methods: {
    parseISO,
    format,
  },
};
</script>
<style>
.bottomButtonWrapper {
  position: fixed;
  bottom: 0;
  display: flex;
  height: 60px;
  width: 100%;
}

.backButton {
  flex: 1;
  height: 100%;
  background-color: #c7c7c7;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2b2b2b;
  font-size: 15px;
}

.creataContractButton {
  flex: 1;
  height: 100%;
  background-color: #404040;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 15px;
}
</style>
